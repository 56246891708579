import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import { TokenStorageService } from '../../_services/token-storage.service';

@Component({
  selector: 'app-whisky-card',
  templateUrl: './whisky-card.component.html',
  styleUrls: ['./whisky-card.component.css']
})
export class WhiskyCardComponent implements OnInit {

  isLoggedIn : boolean = false;

  constructor (private router: Router,
              private tokenStorageService: TokenStorageService) { }

  @Input() whisky: any; //TODO define type.
  
  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();
  }

  goToWhiskyRatings(id_whisky) {
    this.router.navigate(['/ratings', id_whisky]);
  }

}
