<div class="page-wrapper">
    <div class="page-panel">

        <div class="content mat-body-1">
            <p>SMAC is the Single Malt Appreciation Club in Canterbury New Zealand.</p>

            <p>Ideated in 2009 at an event in Little River where an adhoc group of whisky drinkers lamented the high cost of purchasing new bottles that you may or may not like. A concept was born to share the cost across a small group to try a range of bottles. If a member liked the whisky they could then purchase their own bottle in confidence.</p>

            <p>A club was formed, and has blossomed over the last several years.</p>

            <p>The club meets every two months, for which two members are nominated to select a small range of bottles, and these are blind tasted and rated by the rest of the club.A summary of these ratings can be publically found on this website.</p>

            <p>The SMAC rating system is a simple 1-10, with 1 being the worst single malt ever, 10 the best, and 5 as 'one you would buy yourself'.</p>

            <p>Meetings are generally held in secret remote huts among the splendid landscape of the New Zealand South Island, providing for superb whisky drinking vistas. Meetings are always overnight, fostering a long evening of excellent food, whisky, and free of post-drinking travel logistics. </p>

            <p>SMAC is probably the most awesome club ever.</p>

            <p>We have an awesome time doing it, and we share are results here with you.</p>
        </div>
    </div>
</div>
