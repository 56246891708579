import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';
// import * as d3 from 'd3';
//import * as makeDistroChart from 'src/assets/libraries/D3_Reusable_Charts/distro_chart/distrochart.js';

// declare var d3: any;
declare var makeDistroChart: any;

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css'
                // '../../assets/libraries/D3_Reusable_Charts/distro_chart/distrochart.css'
          ]
})
export class StatisticsComponent implements OnInit {

  whiskies: any[];
  whiskiesWithMultipleOccurenceBottlers : any[];
  boxplot: any;
  data = [{date:"1/1/12", value:2},
        {date:"1/1/12", value:3},
        {date:"4/1/12", value:3},
        {date:"7/1/12", value:2}];

  constructor(private dataService: DataService) { }

  onResize() {
    this.boxplot.update();
  }

  ngOnInit(): void {

    this.dataService.getWhiskies().subscribe(
      data => {
        this.whiskies = data.result;

        function getNotUnique(array) {
          var map = new Map();
          array.forEach(a => map.set(a.bottler_name, (map.get(a.bottler_name) || 0) + 1));
          return array.filter(a => map.get(a.bottler_name) > 1);
        }

        /* Ensure we only include bottlers with more than one whisky - to reduce graph size */
        this.whiskiesWithMultipleOccurenceBottlers = getNotUnique(this.whiskies);

        // console.log(this.bottlers);

        this.boxplot = makeDistroChart({
          data:this.whiskiesWithMultipleOccurenceBottlers,
          xName:'bottler_name',
          yName:'club_rating_average',
          axisLabels: {xAxis: null, yAxis: 'Values'},
          selector:"#chart-distro1",
          margin:{top: 15, right: 40, bottom: 165, left: 50},
          constrainExtremes:true});
        this.boxplot.renderBoxPlot();



      },
      err => {
        //TODO something better here
        //this.meetings = JSON.parse(err.error).message;
        this.whiskies=[];
        console.log("Failed to retrieve data on Home page");
      }
    );




  }

}
