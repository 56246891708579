import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { TokenStorageService } from '../_services/token-storage.service';

@Component({
  selector: 'app-ratings',
  templateUrl: './ratings.component.html',
  styleUrls: ['./ratings.component.css']
})
export class RatingsComponent implements OnInit {

  whiskyRating: [] = null;
  id_whisky: number;
  private sub: any;

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private location: Location,
              private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {

    // const id_whisky = +this.route.snapshot.paramMap.get('id_whisky');
    // console.log(id_whisky);

    this.sub = this.route.params.subscribe(params => {
      this.id_whisky = +params['id_whisky']; // (+) converts string 'id' to a number

      //  dispatch action to load the details here.
      this.dataService.getWhiskyRatings(this.id_whisky).subscribe(
        data => {
          /* Returns an array at present - just grab item 0 */
          this.whiskyRating = data.result[0];
        },
        err => {
          //TODO something better here
          //this.meetings = JSON.parse(err.error).message;
          // this.whiskyRating=[];
          console.log("Failed to retrieve data on Home page");
        }
      );
    });
  }


  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
