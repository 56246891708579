<div class="app-container" >

    <mat-toolbar class="app-toolbar" color="primary-900" routerLinkActive="active">
        <div fxShow="true" fxHide.gt-sm="true">
            <button mat-icon-button (click)="sidenav.toggle()">
              <mat-icon>menu</mat-icon>
            </button>
          </div>
        <span>SMAC - A Whisky Club</span>
        <span fxFlex></span>
        <div fxShow="true" fxHide.lt-md="true">
            <a mat-button href="/home" routerLink="/home">Home</a>
            <a mat-button href="/meetings" routerLink="/meetings">Meetings</a>
            <a mat-button href="/whiskies" routerLink="/whiskies">Whiskies</a>
            <a mat-button href="/statistics" routerLink="/statistics">Statistics</a>
            <a mat-button href="/about" routerLink="/about">About</a>
            <a mat-button href="/member" routerLink="/member" *ngIf="showMember">Members</a>
            <a mat-button href="/admin" routerLink="/admin" *ngIf="showAdmin">Admin</a>

            <a mat-raised-button href="/login" routerLink="/login" *ngIf="!isLoggedIn" color="accent">Login</a>

            <a mat-button href="/profile" routerLink="/profile" *ngIf="isLoggedIn">{{ username }}</a>
            <a mat-raised-button href="/login" (click)="logout()" *ngIf="isLoggedIn" color="accent">LogOut</a>
        </div>
    </mat-toolbar>


    <mat-sidenav-container class="app-main">
      
    <mat-sidenav color="primary" #sidenav fxLayout="column" mode="over"  opened="false" fxHide.gt-sm="true">
        <div fxLayout="column" (click)="sidenav.close()">
            <a mat-button href="/home" routerLink="/home">Home</a>
            <a mat-button href="/meetings" routerLink="/meetings">Meetings</a>
            <a mat-button href="/whiskies" routerLink="/whiskies">Whiskies</a>
            <a mat-button href="/statistics" routerLink="/statistics">Statistics</a>
            <a mat-button href="/about" routerLink="/about">About</a>
            <a mat-button href="/member" routerLink="/member" *ngIf="showMember">Members</a>
            <a mat-button href="/admin" routerLink="/admin" *ngIf="showAdmin">Admin</a>

            <a mat-raised-button href="/login" routerLink="/login" *ngIf="!isLoggedIn" color="accent">Login</a>

            <a mat-button href="/profile" routerLink="/profile" *ngIf="isLoggedIn">{{ username }}</a>
            <a mat-raised-button href="/login" (click)="logout()" *ngIf="isLoggedIn" color="accent">LogOut</a>
        </div>
      </mat-sidenav>
      <mat-sidenav-content class="app-main">
        <img src="assets/images/002.jpg" class="bg" >
        <div class="app-main">
            <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>




</div>   
