import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { WhiskiesComponent } from './whiskies/whiskies.component';
import { MeetingsComponent } from './meetings/meetings.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { RatingsComponent } from './ratings/ratings.component';
import { AboutComponent } from './about/about.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  // { path: 'register', component: RegisterComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'whiskies', component: WhiskiesComponent },
  { path: 'meetings', component: MeetingsComponent },
  { path: 'statistics', component: StatisticsComponent },
  { path: 'ratings/:id_whisky', component: RatingsComponent },
  { path: 'about', component: AboutComponent },
  // { path: 'user', component: BoardUserComponent },
  // { path: 'mod', component: BoardModeratorComponent },
  // { path: 'admin', component: BoardAdminComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
