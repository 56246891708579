
<div class="page-wrapper">
    <div class="page-panel">
        <div *ngIf="whiskyRating" class="content mat-body-1">

            <div class="datelabel" >{{whiskyRating.whisky.meeting_date}}</div>

            <div class="whiskyname">
                <span *ngIf="whiskyRating.whisky.name">{{whiskyRating.whisky.name}}</span>
                <span *ngIf="!whiskyRating.whisky.name">Unnamed</span>
            </div>

            <div class="review" >{{whiskyRating.whisky.description}}</div>

            <div>
                <mat-icon class="label-icon" color="accent">local_shipping</mat-icon>
                <span class="bottlerlabel">{{whiskyRating.whisky.bottler_name}}</span>
            </div>
            <div>
                <mat-icon class="label-icon" color="accent">home</mat-icon>
                <span class="distillerylabel">{{whiskyRating.whisky.distillery}}</span>
            </div>

            <div>
                <mat-icon class="label-icon" color="accent">new_releases</mat-icon>
                <div class="ABVlabel">{{whiskyRating.whisky.ABV}} %</div>
            </div>
            <div class="review">{{whiskyRating.whisky.smac_review}}</div>


            <div *ngFor="let member of whiskyRating.ratings" class="md-long-text" ng-click="null">
                <!-- show this DIV as default in list -->
                <div class="md-list-item-text" layout="row">
                    <p class="md-body-1"><span class="md-body-2">{{member.score}} {{member.member_name}} </span> {{member.description}}</p>
                    <md-button *ngIf="tokenStorageService.isAdminEditMode()" class="md-icon-button" aria-label="Edit Rating" ng-click="showRatingEditor(member)">
                        <mat-icon>edit</mat-icon>
                    </md-button>
                    <md-button *ngIf="tokenStorageService.isAdminEditMode()" class="md-icon-button" aria-label="Delete Rating" ng-click="confirmDeleteRating(member.id_rating)">
                        <mat-icon>delete_forever</mat-icon>
                    </md-button>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- <md-button ng-show="!read_only_mode" class="md-icon-button" aria-label="Add Rating" ng-click="showRatingEditor()">
	<md-icon><i class="material-icons">playlist_add</i></md-icon>
</md-button> -->
