<div class="page-wrapper">
  <div class="page-panel">
    <div class="content">
      <h2 class="mat-h2">Last Meeting</h2>
      <div class="icon-text" fxLayoutGap="16px">
        <mat-icon color="accent">location_on</mat-icon>
        <span class="body-1">{{last_meeting_location}}</span>
      </div>
      <div class="icon-text" fxLayoutGap="16px">
        <mat-icon color="accent">event_available</mat-icon>
        <span class="body-1">{{last_meeting_date}}</span>
      </div>
    </div>

    <div class="content" fxLayout="row wrap" fxLayoutGap="16px grid">
      <app-whisky-card fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" 
      *ngFor="let whisky of last_meeting_whiskies"
      [whisky]="whisky">
      </app-whisky-card>
    </div>

    <div class="content">
      <h2 class="mat-h2">Top 4 whiskies of the year.</h2>
    </div>

    <div class="content" fxLayout="row wrap" fxLayoutGap="16px grid">
      <app-whisky-card fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" 
      *ngFor="let whisky of recentTopWhiskies | whiskySort:selectedSortType:sortDirectionAscending | slice:0:4"
      [whisky]="whisky">
      </app-whisky-card>
    </div>
  </div>
</div>