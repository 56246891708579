import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.css']
})
export class MeetingsComponent implements OnInit {

  meetings: [];
  selected_meeting: any;
  selected_meeting_whiskies: any;

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    /* Retrieve meetings */
    this.dataService.getMeetings().subscribe(
      data => {
        //TODO: return from service as JSON to start with!
        this.meetings = JSON.parse(data).result;

        this.meetings.sort(function(a: any, b:any) {
          if (a.meeting.meeting_date > b.meeting.meeting_date) {
            return -1;
          }
    
          if (a.meeting.meeting_date < b.meeting.meeting_date) {
              return 1;
          }
        });
        this.selected_meeting = this.meetings[Object.keys(this.meetings)[0]];
        //this.selected_meeting = this.meetings[Object.keys(this.meetings)[Object.keys(this.meetings).length - 1]];
        this.selected_meeting_whiskies = this.selected_meeting.whiskies;
        console.log(this.selected_meeting);
      },
      err => {
        //TODO something better here
        //this.meetings = JSON.parse(err.error).message;
        console.log("Failed to retrieve data on Home page");
      }
    );
  }

  selectMeeting(meeting: any) {
    this.selected_meeting = meeting;
    this.selected_meeting_whiskies = this.selected_meeting.whiskies;
    console.log(meeting);
  }

}
