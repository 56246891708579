
<mat-card class="mat-elevation-z4" (click)="isLoggedIn && goToWhiskyRatings(whisky.id_whisky)" [ngClass]="{'show-cursor': isLoggedIn}">

    <div class="datelabel" >{{whisky.meeting_date}}</div>

    <div class="whiskyname">
        <span *ngIf="whisky.name">{{whisky.name}}</span>
        <span *ngIf="!whisky.name">Unnamed</span>
    </div>

    <div>
        <mat-icon class="label-icon" color="accent">home</mat-icon>
        <span class="distillerylabel">{{whisky.distillery}}</span>
    </div>
    
    <div>
        <mat-icon class="label-icon" color="accent">local_shipping</mat-icon>
        <span class="bottlerlabel">{{whisky.bottler_name}}</span>
    </div>

    
    <div class="agerow">
        <div class="agelabel bg-color-accent">{{whisky.age}} years</div>
        <div class="stars-outer">
            <mat-icon class="stars-icon" color="primary">star</mat-icon>
            <mat-icon class="stars-icon" color="primary">star</mat-icon>
            <mat-icon class="stars-icon" color="primary">star</mat-icon>
            <mat-icon class="stars-icon" color="primary">star</mat-icon>
            <mat-icon class="stars-icon" color="primary">star</mat-icon>
            <div class="stars-inner" [ngStyle]="{'width':(whisky.club_rating_average/10) * 100 + '%'}"> 
                <mat-icon class="stars-icon" color="accent">star</mat-icon>
                <mat-icon class="stars-icon" color="accent">star</mat-icon>
                <mat-icon class="stars-icon" color="accent">star</mat-icon>
                <mat-icon class="stars-icon" color="accent">star</mat-icon>
                <mat-icon class="stars-icon" color="accent">star</mat-icon>
            </div>
        </div>
        <div class="ratinglabel">{{whisky.club_rating_average}}/10</div>
    </div>
    <div>
        <mat-icon class="label-icon" color="accent">new_releases</mat-icon>
        <div class="ABVlabel">{{whisky.ABV}} %</div>
    </div>
    <div class="review">{{whisky.smac_review}}</div>

    <div class="share">
    <!-- matTooltip="Feel free to share me" 
    [matTooltipPosition]="right"
    matTooltipHideDelay="10000"
    aria-label="Share me with tooltip"> -->
    <mat-icon class="label-icon" color="white" >share</mat-icon></div>

</mat-card>

