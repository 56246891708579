<div class="container" *ngIf="currentUser; else loggedOut">

    <h3>
      <strong>{{ currentUser.username }}</strong> Profile
    </h3>

    <p>
      <strong>Token:</strong>
      {{ currentUser.token }}
    </p>
    <p>
      <strong>Email:</strong>
      {{ currentUser.email }}
    </p>
    <strong>Roles:</strong>
    <ul>
      <li *ngFor="let role of currentUser.roles">
        {{ role }}
      </li>
    </ul>
  </div>
  
  <ng-template #loggedOut>
    Please login.
  </ng-template>