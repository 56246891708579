import { Component, OnInit } from '@angular/core';
import { DataService } from '../_services/data.service';
import { WhiskyDateRangePipe } from '../pipes/whisky-date-range.pipe';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  /* Params for recent meetings */
  meetings: [];
  last_meeting: any;
  last_meeting_whiskies: any;
  last_meeting_location: any;
  last_meeting_date: Date;

  /* params for last 4 top whiskies */
  // whiskies: [];
  recentTopWhiskies: any;
  selectedSortType: string = 'club_rating_average'; //TODO this should be a property of the whisky sort sort service.
  sortDirectionAscending: boolean = false;
  todaysDate: Date;
  oneYearAgo: Date;

  constructor(private dataService: DataService,
              private whiskyDateRange: WhiskyDateRangePipe) { }

  //TODO do we need to unsubsribe?
  ngOnInit(): void {
    /* Setup limits for the recent years whiskies */
    this.todaysDate = new Date();
    this.oneYearAgo = new Date();
    this.oneYearAgo.setFullYear(this.oneYearAgo.getFullYear() -1);

    /* Retrieve meetings and process for the most recent meeting details */
    this.dataService.getMeetings().subscribe(
      data => {
        //TODO: return from service as JSON to start with!
        this.meetings = JSON.parse(data).result;

        this.meetings.sort(function(a: any, b:any) {
          if (a.meeting.meeting_date > b.meeting.meeting_date) {
            return 1;
          }
    
          if (a.meeting.meeting_date < b.meeting.meeting_date) {
              return -1;
          }
        });
        this.last_meeting = this.meetings[Object.keys(this.meetings)[Object.keys(this.meetings).length - 1]];
        this.last_meeting_whiskies = this.last_meeting.whiskies;
        this.last_meeting_location = this.last_meeting.meeting.meeting_location;
        this.last_meeting_date = this.last_meeting.meeting.meeting_date;
        // console.log(this.last_meeting_whiskies);
      },
      err => {
        //TODO something better here
        //this.meetings = JSON.parse(err.error).message;
        console.log("Failed to retrieve data on Home page");
      }
    );

    /* retrieve whiskies and process for the recent top whiskies */
    this.dataService.getWhiskies().subscribe(
      data => {
        //TODO put the other pipes straight here also, remove from template.
        let _whiskies = data.result;
        this.recentTopWhiskies = this.whiskyDateRange.transform(_whiskies, this.oneYearAgo, this.todaysDate);
        // console.log("Homepage got whiskies");
        // console.log(this.recentTopWhiskies);
      },
      err => {
        //TODO something better here
        //this.meetings = JSON.parse(err.error).message;
        this.recentTopWhiskies=[];
        console.log("Failed to retrieve data on Home page");
      }
    );
  }

}