import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// const API_URL = 'http://localhost:8080/api/test/';
const API_URL = 'https://smac.nz/server/api/';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  getPublicContent(): Observable<any> {
    return this.http.get(API_URL + 'all', { responseType: 'text' });
  }

  getMeetings(): Observable<any> {
    return this.http.get(API_URL + 'public/meetings', { responseType: 'text' });
  }

  getMembers(): Observable<any> {
    return this.http.get(API_URL + 'member/members', { responseType: 'text' });
  }

  getWhiskies(): Observable<any> {
    return this.http.get(API_URL + 'public/whiskies', { responseType: 'json' });
  }

  getWhiskyRatings(id_whisky): Observable<any> {
    return this.http.get(API_URL + 'member/ratings?id_whisky='+id_whisky, { responseType: 'json' });
  }

  
  // putWhisky(): Observable<any> {
  //   return this.http.put(API_URL + 'admin/whiskies', { responseType: 'text' });
  // }
}